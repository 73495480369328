import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import { Grid, Typography } from '@mui/material';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import CommentPost from './CommentPost';
import {} from './blogpage.style.css';


const BlogPage = () => {
    const location = useLocation();
    const [open, setOpen] = useState(false)
    const pathPart = location.pathname.split("/");
    const navurl = pathPart[pathPart.length-1];
    const [postData, setPostData] = useState({id:"", stitle:"", featuredMedia:'', jetpack_featured_media_url:'', content:''});
    const handleOpen = (pImg) => {
        console.log('pImage', pImg);
        setOpen(true)
    };
    const handleClose = () => setOpen(false);
    // const [figureArr, setFigureArr] = useState([]);

    const checkFigureLarge = (str, pNum=0) => {

        if((str).search('<figure') !== -1){
            const startNum = str.search('<figure');
            let lastNum = str.search('</figure>');
            // console.log(startNum, lastNum, str.search('data-wp-context'), str.substring(lastNum, lastNum+9));
            if(startNum < lastNum && str.search('data-wp-context') !== -1){
                const contextNum = str.search('data-wp-context');
                console.log('contextNum ', contextNum);
                if(contextNum < lastNum && contextNum > startNum){
                    const tempString = str.substring(startNum, lastNum+9);
                    const ele = document.createElement('div');
                    ele.innerHTML = tempString;
                    const firstChild = ele.firstChild;
                    const nextVal = firstChild.attributes['data-wp-context'].value;
                    firstChild.addEventListener('click', ()=>{
                        handleOpen(JSON.parse(nextVal).core.image.imageUploadedSrc);
                    })
                    console.log('sss ', firstChild);
                    lastNum = str.search('</figure>');
                    const remaingingStr = str.substring(lastNum+9, str.length);
                    checkFigureLarge(remaingingStr, lastNum+9);
                } else {
                    const remaingingStr = str.substring(lastNum+9, str.length);
                    checkFigureLarge(remaingingStr);
                }
            }
        } else {
            // return 
        }

        // const remaingingStr = str.substring(, str.length);
        // checkFigureLarge(remaingingStr)
    }


    useEffect(()=>{

        axios.get(`https://beta.uniqueengineersltd.com/blog/wp-json/wp/v2/posts/?slug=${navurl}`)
        .then(res => {
            const {id, title, featured_media, jetpack_featured_media_url, content} = res.data[0];
            // checkFigureLarge(content.rendered);
            // console.log('figures', data);

            setPostData({id, stitle: title.rendered, featuredMedia: featured_media, jetpack_featured_media_url, content: content.rendered})
        })
    }, [navurl]);

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
      };


    return (
        <Grid container sx={{justifyContent:'center'}}>
            <Grid item className='blogpage' xs={12} sx={{mx:'7em', marginTop:0, display:'flex', flexFlow:'column', justifyContent:'center', alignItems:'center'}}>
                <img src={postData.jetpack_featured_media_url} alt="" style={{width:'90%', textAlign:'center' }} />
                <Grid item xs={12} sx={{mb:'1em', mx: '5em'}}>
                <Typography variant="body2" component={'h1'} className='title-color'>
                    {postData.stitle}
                </Typography>
                <Typography variant='body2' component={'p'} className='normal-text' dangerouslySetInnerHTML={{ __html:postData.content !== '' ? postData.content : "No Data" }}></Typography>
                </Grid>
            </Grid>
            <CommentPost postId={postData.id} postSlug ={navurl}/>
            <Modal 
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open}
                onClose={handleClose}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{
                backdrop: {
                    timeout: 500,
                },
                }}
            >
                <Fade in={open}>
                <Box sx={style}>
                    <Typography id="transition-modal-title" variant="h6" component="h2">
                    Text in a modal
                    </Typography>
                    <Typography id="transition-modal-description" sx={{ mt: 2 }}>
                    Duis mollis, est non commodo luctus, nisi erat porttitor ligula.
                    </Typography>
                </Box>
                </Fade>

            </Modal>
        </Grid>
    )
}

export default BlogPage;