import React, { useEffect, useState, useCallback } from 'react';
import { Grid, Button, Typography, Card, CardContent } from '@mui/material';
import Axios from 'axios';
import moment from 'moment';
// import { useNavigate } from 'react-router-dom';

const Blogs = () => {
    const [postData, setPostData] = useState();
    // const navigate = useNavigate();
    const apiURL = 'https://beta.uniqueengineersltd.com/blog/wp-json/wp/v2';

    // const callCategory = useCallback(async(pId) => {
    //     let category_name = ''
    //     await Axios.get(`${apiURL}/categories/?post=${pId}`)
    //     .then(res => {
    //         category_name = res.data.name;
    //     })
    //     return category_name;
    // }, [])
    // const callBlogThumb = useCallback(async(pId) =>{
    //     let resultImage = ''
    //     await Axios.get(`${apiURL}/media/${pId}`)
    //     .then(res => {
    //         resultImage = res.data.media_details.sizes.medium_large.source_url;
    //     })
    //     return resultImage;

    // }, [])

    const fetchPostData = useCallback(async()=>{
        await Axios.get(`${apiURL}/posts/`)
        .then(res => {
            
            const tempArr = [];
            res.data.map((item)=> {
                const urls = [`${apiURL}/categories?post=${item.id}`, `${apiURL}/media/${item.featured_media}`]

                let requests = urls.map(url => Axios.get(url));
                Promise.all(requests)
                .then(responses => {
                    responses.forEach(
                    response => {
                        if(response.data.length){
                            // console.log('response',response.data.length, response.data)
                            item.categories_name = response.data[0].name;
                        } else {
                            item.image_url = response.data.media_details.sizes.medium_large.source_url
                            tempArr.push(item);
                            if(tempArr.length === res.data.length){
                                setPostData(tempArr)
                            }
                        }
                        
                    }
                )})
                

                // const promise1 = Promise.resolve(callBlogThumb(item.featured_media));
                // return promise1.then((data)=>{
                //     item.image_url = data;
                //     tempArr.push(item);
                //     if(tempArr.length === res.data.length){
                //         setPostData(tempArr)
                //     }
                // })
            })
            
        })

    },[])

    // const callPost = (pSlug) => {
    //     // console.log(pSlug)
    //     navigate(`/blogs/${pSlug}`)
    // }

    useEffect(()=>{
        fetchPostData()

    }, [fetchPostData])


    return (
        <Grid container sx={{}}>
            <Grid item xs={12} sx={{display:'flex', justifyContent:'center', textAlign:'center', height: 200, alignItems:'center'}}>
            <Typography variant='h1' component={'h1'} className='title-color' sx={{fontFamily:'Playfair Display', fontWeight:600}}>Blog</Typography>
            </Grid>
            <Grid item xs={12} sx={{mx:'5em'}}>
                <Grid item xs={12} sm={10}>
                    {postData && postData.map((item) => (
                        <Card key={item.id} id={item.id} sx={{ maxWidth: 345, boxShadow:'none' }}>
                        {/* <CardActionArea> */}
                            {
                                item.image_url !== undefined && <img src= { item.image_url} alt='' />
                            }
                          {/* <CardMedia
                            component="img"
                            height= "300"
                            // image= {callBlogThumb(item.featured_media)}
                            image= {item.image_url}
                            alt= {item.title.rendered} 
                            sx={{objectFit:'contain'}}
                          /> */}
                          <CardContent>
                            <Typography gutterBottom variant="body2" component="p" sx={{color:'#27333E'}}>
                            {item.categories_name}
                          </Typography>
                            <Button href={`/blogs/${item.slug}`} className='linkBUtton' variant="text">
                                {item.title.rendered}
                            </Button>
                            <Typography variant="body2" component={'p'} sx={{color:'#27333E'}}>
                                {moment(item.date).format('MMMM Do YYYY')}
                            </Typography>
                          </CardContent>
                        {/* </CardActionArea> */}
                        
                      </Card>
                    ))}
                </Grid>
                <Grid item xs={12} sm={2}></Grid>
            </Grid>
        </Grid>
    )
}

export default Blogs;